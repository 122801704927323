<template>
  <div class="container">
    <div class="content">
      <div class="head-item">
        <!-- 商品类型 -->
        <div class="item-content">
          <label>商品类型</label>
          <el-select v-model="type" placeholder="请选择商品类型">
            <el-option label="全部" :value="1"></el-option>
            <el-option label="实物-仅展示" :value="2"></el-option>
            <el-option label="实物-可支付" :value="3"></el-option>
            <el-option label="虚拟商品" :value="4"></el-option>
            <template slot="prepend">Http://</template>
          </el-select>
        </div>

        <!-- 商品类目 -->
        <div class="item-content">
          <label>商品类目</label>
          <el-select v-model="sort" placeholder="请选择商品类目">
            <el-option v-for="item in sortList" :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

        <!-- 商品类目 -->
        <div class="item-content">
          <label>商品名称</label>
          <el-input style="display: inline-block; width: 195px"
            v-model="input" placeholder="请输入商品名称"></el-input>
        </div>

        <!-- 查询 -->
        <el-button type="primary" style="float: right">查 询</el-button>
      </div>

      <div class="head-item">
        <!-- 下单时间 -->
        <div class="item-content">
          <label>下单时间</label>
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>

        <!-- 完成时间 -->
        <div class="item-content">
          <label>完成时间</label>
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>

      <!-- 订单状态 -->
      <div class="head-item">
        <el-radio-group v-model="orderStatus">
          <el-radio-button label="1">全部订单</el-radio-button>
          <el-radio-button label="2">待付款</el-radio-button>
          <el-radio-button label="3">待发货</el-radio-button>
          <el-radio-button label="4">取消审核</el-radio-button>
          <el-radio-button label="5">已发货</el-radio-button>
          <el-radio-button label="6">已完成</el-radio-button>
        </el-radio-group>
        <el-button style="float: right"
          type="primary" icon="el-icon-upload2"> 导 出</el-button>
      </div>

      <!-- 订单列表 -->
      <div class="order">
        <div class="table-head">
          <span class="item goods">商品</span>
          <span class="item">单价(元)</span>
          <span class="item">数量</span>
          <span class="item">买家</span>
          <span class="item">交易状态</span>
          <span class="item">实收款</span>
          <span class="item">操作</span>
        </div>
        <template v-if="goodsList.length > 0">
          <div class="table-item" v-for="(item, index) in goodsList" :key="index">
            <div class="head">
              <span>订单号：{{item.time}}</span>
              <span>创建时间：{{item.time}}</span>
              <span>支付时间：{{item.time}}</span>
              <span>完成时间：{{item.time}}</span>
            </div>
            <div class="item goods">
              <img :src="item.logo">
              <p>{{item.name}}</p>
            </div>
            <div class="item">￥{{item.price}}</div>
            <div class="item">{{1}}</div>
            <div class="item">种花家</div>
            <div class="item">
              <p>交易完成</p>
              <a type="primary">查看详情</a>
            </div>
            <div class="item">￥{{item.price}}</div>
            <div class="item">
              <p><a type="primary" @click="openDeliverGoodsDialog(item)">发货</a></p>
              <p><a type="primary" @click="examinOrder('pass')">审核通过</a></p>
              <p><a type="primary" @click="examinOrder('repulse')">打回</a></p>
            </div>
          </div>
        </template>
        <!-- 无订单 -->
        <p v-else class="table-none">没有数据</p>
      </div>

      <!-- 分页数据 -->
      <div style="margin-top: 20px">
        <!-- 分页 -->
        <div style="float:right;">
          <el-pagination
            :background="true"
            @current-change="getArticleList"
            :current-page="goodsQuery.pageIndex"
            :page-size="goodsQuery.pageNumber"
            layout="total, prev, pager, next, jumper"
            :total="goodsQuery.pageTotal">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 发货弹窗 -->
    <el-dialog title="发货" width="600px" class="deliver-goods"
      :visible.sync="deliverGoodsDialog">
      <h3>收货人信息</h3>
      <p>姓名：{{deliverGoods.name}}</p>
      <p>电话：{{deliverGoods.tel}}</p>
      <p>地址：{{deliverGoods.address}}</p>
      <h3 style="margin-top: 20px">收货人信息</h3>
      <el-form label-width="80px" :model="formLabelAlign">
        <el-form-item label="物流信息">
          <el-radio v-model="radio" label="1">需要物流</el-radio>
          <el-radio v-model="radio" label="2">无需物流</el-radio>
        </el-form-item>
        <template v-if="radio == 1">
          <el-form-item label="快递公司">
            <el-select v-model="sort" placeholder="请选择快递公司">
              <el-option v-for="item in sortList" :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="快递单号">
            <el-input v-model="formLabelAlign.type" placeholder="请输入快递单号"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverGoodsDialog = false">取 消</el-button>
        <el-button type="primary" @click="deliverGoodsDialog = false">确认发货</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return {
      radio: '1',
      value1: [],
      input: '',
      type: '',
      sort: '',
      sortList: [{name: "水果", value: 1}, {name: "生鲜", value: 2}],
      orderStatus: "1",
      goodsQuery: {
        pageIndex: 1,
        pageNumber: 10,
        pageTotal: 0,
        title: '',
        status: 0,
        type: 1,
        sort: "",
        sortList: [{name: "水果", value: 1}, {name: "生鲜", value: 2}]
      },
      goodsList:[], // 商品列表
      deliverGoodsDialog: false, // 填写发货信息弹窗
      deliverGoods: { // 发货信息
        name: '',
        tel: '',
        address: ''
      },
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      },
      tempList: []
    }
  },
  mounted(){
    this.getOrderList();
  },
  methods: {
    // 添加商品
    addGoods(){
      window.open("#/shop/goods/add");
    },

    // 获取商品列表
    async getOrderList(){
      let res = await this.$ZGManager.getOrderList(this.$sessionStorage('ZGCMS_ACCOUNT').companyId);
      if(res.status == 200){
        for(let i = 0; i < 3; i++){
          this.goodsList.push({
            id: 123123,
            logo: "https://image.qll-times.com/2020/07/1bf486a77ee44e289f0488de30d3a79e.png",
            name: "测试商品测试商品测试商品测试商品测试商品测试商品测试商品测试商品测试商品测试商品测试商品",
            sort: "加推服务产品",
            type: "实体商品-支持线上支付",
            price: 10.8,
            num: 0,
            time: '2020-03-09 12:23:23'
          });
        }
      }
    },

    handleSelectionChange(){

    },
    async updateStatus(item, type){
      switch(type){
        case '':
          break;
        case 'del':
          let dialog = await this.$confirm(`您确定要删除 “${item.name}” 吗?`, '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error'
          });
          if(dialog != 'confirm') return;
          break;
        default: this.$message.error("无效操作");
      }
    },
    getArticleList(){

    },

    // 打开填写发货信息弹窗
    openDeliverGoodsDialog(item){
      this.deliverGoods = {
        name: item.name,
        tel: '13390513859',
        address: '北京市海淀区唐家岭路百旺宏祥1989科技文化产业园H8216'
      }
      this.deliverGoodsDialog = true;
    },

    // 审核订单
    async examinOrder(type){
      let title, tips;
      if(type == 'pass'){
        title = "通过审核后该笔订单款项将原路返回给客户";
        tips = "审核取消订单";
      }else if(type == 'repulse'){
        title = "打回后订单将恢复待发货状态，建议与客户充分沟通后再操作";
        tips = "审核不通过";
      }
      let dialog = await this.$confirm(title, tips, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background-color: #fff;
  padding: 15px;
  overflow: hidden;
}
.head-item{
  margin-bottom: 10px;
  .item-content{
    display: inline-block;
    margin-right: 30px;
    label{
      font-size: 14px;
      color: #666;
      margin-right: 5px;
    }
  }
}
.operation-button{
  margin: 0;
  text-align: left;
  color: #6e87db;
}
.order{
  color: #3c3c3c;
  font-size: 12px;
  overflow: hidden;
}
.table-head{
  width: 100%;
  background-color: rgb(242, 246, 255);
  line-height: 35px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  span{
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 0!important;
    font-weight: bold;
  }
  .goods{
    padding: 0 10px!important;
    text-align: center!important;
  }
}
.table-item{
  border: 1px solid #ececec;
  margin-bottom: 5px;
  overflow: hidden;
  .head{
    width: 100%;
    background-color: #eaf8ff;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    span{
      margin-right: 20px;
    }
  }
}
.table-none{
  color: #666666;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
}
.goods{
  width: calc(40% - 20px)!important;
  overflow: hidden;
  text-align: left!important;
  padding: 10px!important;
  img{
    width: 50px;
    height: 100%;
    vertical-align: text-top;
    float: left;
  }
  p{
    width: calc(100% - 70px) ;
    padding: 0 10px;
    display: inline-block;
  }
}
.item{
  float: left;
  width: calc(60% / 6);
  vertical-align: top;
  text-align: center;
  padding: 10px 0;
  a{
    color: #6e87bd;
  }
}
.deliver-goods{
  ::v-deep .el-dialog__body{
    padding-top: 20px;
    border-top: 1px solid #ececec;
  }
  h3{
    font-weight: bold;
    margin-bottom: 15px;
  }
  p{
    line-height: 25px;
  }
}
</style>